<template>
  <main>
    <section class="bg-secondary overflow-hidden">
      <div class="container-fluid mw-1440">
        <div class="row">
          <div class="col-12 col-lg-6 px-0 align-self-lg-stretch">
            <section class="main-banner h-100">
              <div class="container-fluid h-100">
                <div class="row py-3 position-relative h-100">
                  <div
                    class="col-12 d-flex align-self-center text-center justify-content-center align-items-center h-100"
                  >
                    <img
                      src="@/assets/images/Circulo_Completo.svg"
                      alt="Olx"
                      class="banner-figure animate__animated animate__zoomIn"
                    />
                    <div
                      class="banner-content animate__animated animate__fadeIn"
                    >
                      <h1 class="font-weight-bold">
                        {{ $t("banner.title") }}
                      </h1>
                      <h4 class="blue-tick-list">
                        {{ $t("banner.subtitle") }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="col-12 col-lg-5 bg-white fix-index">
            <section class="py-4">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12">
                    <h2 class="font-weight-bold d-block text-center mb-3">
                      {{ $t("sell-form.title") }}
                    </h2>
                    <PlateForm v-if="VUE_APP_COUNTRY == 'CL'" />
                    <hr class="mx-5 bg-primary" />
                    <QuoteForm />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>

    <section class="py-4">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <h4 class="font-weight-bold mb-3">
              {{ $t("how-to-sell.title") }}
            </h4>
          </div>
          <div class="col-12 col-lg-4 text-center mb-3">
            <img
              v-animate-onscroll="'animate__animated animate__fadeIn'"
              src="@/assets/icons/icon_presell.svg"
              class="mb-2"
              :alt="$t('how-to-sell.icon1')"
            />
            <h5
              v-if="$t('how-to-sell.icon1-title') !== 'how-to-sell.icon1-title'"
              v-animate-onscroll="'animate__animated animate__fadeInUp'"
              class="mb-2 font-weight-bold"
            >
              {{ $t("how-to-sell.icon1-title") }}
            </h5>
            <p
              v-animate-onscroll="'animate__animated animate__fadeInUp'"
              class="mb-0"
            >
              {{ $t("how-to-sell.icon1") }}
            </p>
          </div>
          <div class="col-12 col-lg-4 text-center mb-3">
            <img
              v-animate-onscroll="'animate__animated animate__fadeIn'"
              src="@/assets/icons/icon_inspection.svg"
              class="mb-2"
              :alt="$t('how-to-sell.icon2')"
            />
            <h5
              v-if="$t('how-to-sell.icon2-title') !== 'how-to-sell.icon2-title'"
              v-animate-onscroll="'animate__animated animate__fadeInUp'"
              class="mb-2 font-weight-bold"
            >
              {{ $t("how-to-sell.icon2-title") }}
            </h5>
            <p
              v-animate-onscroll="'animate__animated animate__fadeInUp'"
              class="mb-0"
            >
              {{ $t("how-to-sell.icon2") }}
            </p>
          </div>
          <div class="col-12 col-lg-4 text-center mb-0">
            <img
              v-animate-onscroll="'animate__animated animate__fadeIn'"
              src="@/assets/icons/icon_sell.svg"
              class="mb-2"
              :alt="$t('how-to-sell.icon3')"
            />
            <h5
              v-if="$t('how-to-sell.icon3-title') !== 'how-to-sell.icon3-title'"
              v-animate-onscroll="'animate__animated animate__fadeInUp'"
              class="mb-2 font-weight-bold"
            >
              {{ $t("how-to-sell.icon3-title") }}
            </h5>
            <p
              v-animate-onscroll="'animate__animated animate__fadeInUp'"
              class="mb-0"
            >
              {{ $t("how-to-sell.icon3") }}
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-light py-4">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h4 class="font-weight-bold mb-1">{{ $t("benefits.title") }}</h4>
            <p>{{ $t("benefits.subtitle") }}</p>
          </div>
          <div
            v-for="item of $t('benefits.list-items')"
            :key="item[0]"
            class="col-12 col-lg-3"
          >
            <ul class="blue-tick-list light-blue-tick">
              <li>
                <span class="font-weight-bold d-block mb-2">{{
                  item.title
                }}</span>
                <span>
                  {{ item.content }}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import PlateForm from "@/components/PlateForm";
import QuoteForm from "@/components/QuoteForm";

export default {
  name: "Home",
  components: {
    PlateForm,
    QuoteForm,
  },
  data() {
    return { VUE_APP_COUNTRY: process.env.VUE_APP_COUNTRY };
  },
};
</script>
