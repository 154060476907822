"use strict";
import i18n from "../plugins/i18n";
import router from "../router";

export default {
  namespaced: true,
  state: {
    isComplete: false,
    leadId: "",
    loading: false,
    error: false,
    makers: [],
    years: [],
    models: [],
    versions: [],
    quoteRange: { min: 0, max: 0 },
    newForm: [
      {
        name: "maker",
        label: "",
        placeholder: i18n.t("sell-form.make-input"),
        value: "",
        error: false,
        checked: false,
        options: [],
        disabled: false,
        type: "select",
      },
      {
        name: "year",
        label: "",
        placeholder: i18n.t("sell-form.year-input"),
        value: "",
        error: false,
        checked: false,
        options: [],
        disabled: true,
        type: "select",
      },
      {
        name: "model",
        label: "",
        placeholder: i18n.t("sell-form.model-input"),
        value: "",
        error: false,
        checked: false,
        options: [],
        disabled: true,
        type: "select",
      },
      {
        name: "version",
        label: "",
        placeholder: i18n.t("sell-form.version-input"),
        value: "",
        error: false,
        checked: false,
        options: [],
        disabled: true,
        type: "select",
      },
      {
        name: "milage",
        label: "",
        placeholder: i18n.t("sell-form.milage-input"),
        value: "",
        error: false,
        checked: false,
        options: [5000,
          10000,
          20000,
          30000,
          40000,
          50000,
          60000,
          70000,
          80000,
          90000,
          100000,
          125000,
          150000,
          200000,
          300000,
          400000,
          500000],
        disabled: true,
        type: "select",
      },
      {
        name: "email",
        label: "",
        placeholder: i18n.t("sell-form.email-input"),
        value: "",
        error: false,
        checked: false,
        disabled: false,
        type: "text",
      },
      {
        name: "phone",
        label: "",
        placeholder: i18n.t("sell-form.phone-input.placeholder"),
        countryCode: i18n.t("sell-form.phone-input.suffix"),
        value: "",
        error: false,
        checked: false,
        disabled: false,
        type: "phone",
      },
      {
        name: "terms",
        label: "",
        placeholder: "",
        value: true,
        error: false,
        checked: true,
        disabled: false,
        type: "checkbox",
      },
    ],
  },
  mutations: {
    SET_LEAD_ID(state, leadId) {
      state.leadId = leadId;
    },
    SET_MAKERS(state, makers) {
      const fieldIndex = state.newForm.findIndex(
        field => field.name == 'maker'
      );

      state.newForm[fieldIndex].options = makers
    },
    SET_YEARS(state, years) {
      const fieldIndex = state.newForm.findIndex(
        field => field.name == 'year'
      );

      state.newForm[fieldIndex].options = years
    },
    SET_MODELS(state, models) {
      const fieldIndex = state.newForm.findIndex(
        field => field.name == 'model'
      );

      state.newForm[fieldIndex].options = models
    },
    SET_VERSIONS(state, versions) {
      const fieldIndex = state.newForm.findIndex(
        field => field.name == 'version'
      );

      state.newForm[fieldIndex].options = versions;
    },
    SET_VALUES(state, field) {
      const { name, value, disabled, error } = field;

      const fieldIndex = state.newForm.findIndex(
        field => field.name == name
      );

      state.newForm[fieldIndex].value = value;
      state.newForm[fieldIndex].disabled = disabled;
      state.newForm[fieldIndex].error = error;
    },
    CLEAR_VALUES(state) {
      const fields = ['year', 'model', 'version']

      fields.forEach(fieldName => {
        const fieldIndex = state.newForm.findIndex(
          field => field.name == fieldName
        );

        state.newForm[fieldIndex].value = "";
        state.newForm[fieldIndex].options = []
      })
    },
    SET_FORM(state, payload) {
      const { make, year, model } = payload;

      const fields = ['maker', 'year', 'model']



      fields.forEach(fieldName => {

        switch (fieldName) {
          case 'maker': {
            const fieldIndex = state.newForm.findIndex(
              field => field.name == 'maker'
            );
            state.newForm[fieldIndex].value = make;
          }
            break;
          case 'year': {
            const fieldIndex = state.newForm.findIndex(
              field => field.name == 'year'
            );

            state.newForm[fieldIndex].value = year;
          }
            break;
          case 'model': {
            const fieldIndex = state.newForm.findIndex(
              field => field.name == 'model'
            );
            state.newForm[fieldIndex].value = model;
          }
            break;
          default:
            break;
        }
      })

    },
    SET_QUOTE_RANGE(state, quoteRange) {
      state.quoteRange.min = quoteRange[0];
      state.quoteRange.max = quoteRange[1];
    },
    SET_COMPLETE(state, isComplete) {
      state.isComplete = isComplete;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    UPDATE_COMPLETE({ commit }, payload) {

      commit("SET_COMPLETE", payload);
    },
    UPDATE_FORM({ commit }, payload) {

      commit("SET_FORM", payload);
    },
    UPDATE_FORM_VALUES({ commit }, field) {
      commit("SET_VALUES", field);
    },
    CLEAR_FORM_VALUES({ commit }) {
      commit("CLEAR_VALUES");
    },
    async CREATE_LEAD({ commit }, payload) {
      const placeId = process.env.VUE_APP_PLACE_ID;
      const {
        maker,
        year,
        model,
        version,
        mileage,
        plate,
        email,
        phone,
        minPrice,
        maxPrice,
      } = payload;

      const query = {
        plate,
        make: maker,
        model,
        trim: version,
        year,
        mileage: parseInt(mileage),
        email,
        placeId,
        phone,
        minPrice,
        maxPrice: maxPrice ? maxPrice : 0,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      };

      fetch(
        `${process.env.VUE_APP_SELF_INSPECTION_API}createCarLead`,
        requestOptions
      )
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              createCarLeadCallback: { id },
            },
          } = data;

          commit("SET_LEAD_ID", id);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.error("There was an error!", error);
        });
    },
    async GET_QUOTE({ commit }, payload) {
      commit("SET_LOADING", true);

      const { maker, year, model, version, milage, user } = payload;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query:
            '{quote(country:"' + process.env.VUE_APP_COUNTRY + '", make:"' +
            maker +
            '",model:"' +
            model +
            '" ,mileage:' +
            parseInt(milage) +
            ",year:" +
            year +
            ',trim:"' +
            version +
            '",userIdentifier:"' +
            user +
            '", sellable: SELLABLE){quote,variant,priceText}}',
        }),
      };

      fetch(`${process.env.VUE_APP_SELF_INSPECTION_API}cardata`, requestOptions)
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              quote: { quote },
            },
          } = data;

          commit("SET_QUOTE_RANGE", quote);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);

          router.push({ path: "/quote" });
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.error("There was an error!", error);
        });
    },
    async GET_MAKERS({ commit }) {
      commit("SET_LOADING", true);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query: '{make(country:"' + process.env.VUE_APP_COUNTRY + '"){list}}' }),
      };

      fetch(`${process.env.VUE_APP_SELF_INSPECTION_API}cardata`, requestOptions)
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              make: { list },
            },
          } = data;

          commit("SET_MAKERS", list);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.error("There was an error!", error);
        });
    },
    async GET_YEARS({ commit }, maker) {
      commit("SET_LOADING", true);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query: '{year(country:"' + process.env.VUE_APP_COUNTRY + '",make:"' + maker + '"){list}}',
        }),
      };

      fetch(`${process.env.VUE_APP_SELF_INSPECTION_API}cardata`, requestOptions)
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              year: { list },
            },
          } = data;

          commit("SET_YEARS", list);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.error("There was an error!", error);
        });
    },
    async GET_MODELS({ commit }, queryParams) {
      commit("SET_LOADING", true);

      const { maker, year } = queryParams;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query:
            '{model(country:"' + process.env.VUE_APP_COUNTRY + '",make:"' +
            maker +
            '",year:' +
            year +
            "){list}}",
        }),
      };

      fetch(`${process.env.VUE_APP_SELF_INSPECTION_API}cardata`, requestOptions)
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              model: { list },
            },
          } = data;

          commit("SET_MODELS", list);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.error("There was an error!", error);
        });
    },
    async GET_VERSIONS({ commit }, queryParams) {
      commit("SET_LOADING", true);

      const { maker, year, model } = queryParams;

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query:
            '{trim(country:"' + process.env.VUE_APP_COUNTRY + '",make:"' +
            maker +
            '",year:' +
            year +
            ',model:"' +
            model +
            '"){list}}',
        }),
      };

      fetch(`${process.env.VUE_APP_SELF_INSPECTION_API}cardata`, requestOptions)
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              trim: { list },
            },
          } = data;

          commit("SET_VERSIONS", list);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.error("There was an error!", error);
        });
    },
  },
  getters: {
    newForm: state => state.newForm,
    leadId: state => state.leadId,
    quoteRange: state => state.quoteRange,
    makers: state => state.makers,
    loading: state => state.loading,
    isComplete: state => state.isComplete,
    error: state => state.error,
  },
};
