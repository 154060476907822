<template>
  <form>
    <p class="d-none">
      {{ carData }}
    </p>
    <QuoteFormField
      v-for="item in newForm"
      :key="item.name"
      :field="item"
      :on-change="onChange"
    />

    <button
      class="btn btn-primary btn-lg w-100"
      :disabled="!isComplete"
      @click.prevent="onFormSubmit"
    >
      {{ $t("sell-form.submit-button") }}
    </button>
  </form>
</template>

<script>
import { mapActions } from "vuex";
import QuoteFormField from "./QuoteFormField";

export default {
  name: "QuoteForm",
  components: {
    QuoteFormField,
  },
  computed: {
    carData: {
      get() {
        return this.$store.state.form.carData;
      },
    },
    isComplete: {
      get() {
        return this.$store.state.quote.isComplete;
      },
    },
    newForm: {
      get() {
        return this.$store.state.quote.newForm;
      },
    },
    maker: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "maker"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    year: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "year"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    model: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "model"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    milage: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "milage"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    version: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "version"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    email: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "email"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    terms: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "terms"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
  },
  mounted() {
    this.GET_MAKERS();
  },
  updated() {
    if (this.carData.make) {
      this.UPDATE_FORM(this.carData);

      this.GET_VERSIONS({
        maker: this.carData.make,
        year: this.carData.year,
        model: this.carData.model,
      });
    }
  },
  methods: {
    ...mapActions({
      CLEAR_CAR_DATA: "form/CLEAR_CAR_DATA",
      UPDATE_FORM: "quote/UPDATE_FORM",
      UPDATE_FORM_VALUES: "quote/UPDATE_FORM_VALUES",
      CLEAR_FORM_VALUES: "quote/CLEAR_FORM_VALUES",
      GET_QUOTE: "quote/GET_QUOTE",
      GET_MAKERS: "quote/GET_MAKERS",
      GET_YEARS: "quote/GET_YEARS",
      GET_MODELS: "quote/GET_MODELS",
      GET_VERSIONS: "quote/GET_VERSIONS",
      UPDATE_COMPLETE: "quote/UPDATE_COMPLETE",
    }),
    handleOnComplete() {
      const isComplete = this.newForm.every((f) => f.value);

      this.newForm.forEach((f) => {
        if (f.error) {
          console.log(f);
        } else {
          if (isComplete) {
            this.UPDATE_COMPLETE(isComplete);
          }
        }
      });
    },
    onFormSubmit() {
      window.dataLayer.push({
        event: "gaEvent",
        eventCategory: "evo-evadis",
        eventAction: "click",
        eventLabel: "SUBMIT| Quote",
      });

      this.GET_QUOTE({
        maker: this.maker,
        year: this.year,
        model: this.model,
        milage: this.milage,
        version: this.version,
        user: this.email,
      });
    },
    onChange(event) {
      const field = event.target.name;
      const value = event.target.value;
      const checked = event.target.checked;

      this.UPDATE_FORM_VALUES({
        name: field,
        value,
        disabled: value.lenght ? false : true,
        checked,
      });

      this.handleOnComplete();

      switch (field) {
        case "maker":
          this.CLEAR_CAR_DATA();
          this.CLEAR_FORM_VALUES();
          this.GET_YEARS(value);
          break;
        case "year":
          this.GET_MODELS({ maker: this.maker, year: this.year });
          break;
        case "model":
          this.GET_VERSIONS({
            maker: this.maker,
            year: this.year,
            model: this.model,
          });
          break;
        case "milage":
          this.UPDATE_FORM_VALUES({
            name: field,
            value: value.match(/\d+/g).join(""),
            disabled: value.lenght ? false : true,
            checked,
          });
          break;
        case "terms":
          this.UPDATE_FORM_VALUES({
            name: field,
            value: checked,
            checked,
          });
          break;
        case "email":
          this.UPDATE_FORM_VALUES({
            name: field,
            value,
            checked,
            error: value.match(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            )
              ? false
              : true,
          });
          break;
        case "phone":
          this.UPDATE_FORM_VALUES({
            name: field,
            value,
            checked,
            error: value.match(/^(?!0)\d{9}$/) ? false : true,
          });
          break;
        default:
      }
    },
  },
};
</script>
