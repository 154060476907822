<template>
  <b-navbar toggleable="lg" type="light" class="fix-index">
    <a href="/">
      <b-navbar-brand class="mr-0">
        <img
          src="@/assets/images/olx_logo.svg"
          alt="Olx Autos | Venta Inmediata"
          class="img-fluid olx-logo"
        />
      </b-navbar-brand>
    </a>
    <b-navbar-toggle target="nav-collapse">
      <IconToggle />
    </b-navbar-toggle>

    <b-collapse id="nav-collapse" class="justify-content-lg-end" is-nav>
      <b-navbar-nav>
        <b-nav-item href="https://www.olxautos.cl/como-vender/" target="_blank">
          ¿Cómo vender?
        </b-nav-item>
        <b-nav-item
          href="https://www.olxautos.cl/quienes-somos/"
          target="_blank"
        >
          ¿Quiénes somos?
        </b-nav-item>
        <b-nav-item
          href="https://www.olxautos.cl/donde-estamos/"
          target="_blank"
        >
          ¿Dónde estamos?
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import IconToggle from "@/components/IconToggle";
export default {
  components: {
    IconToggle,
  },
};
</script>
