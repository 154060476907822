<template>
  <div class="form-group">
    <select
      v-if="field.type === 'select'"
      class="custom-select"
      :class="{ 'control-valid': !!field.value.length }"
      :name="field.name"
      :disabled="!field.options.length"
      @change="handleOnChange($event)"
    >
      <option value="" selected>
        {{ field.value || field.placeholder }}
      </option>
      <option v-for="option in field.options" :key="option" :value="option">
        {{ option }}
      </option>
    </select>

    <input
      v-if="field.type === 'text' && field.name === 'milage'"
      type="text"
      class="form-control"
      :class="{ 'control-valid': !!field.value }"
      :name="field.name"
      :value="field.value"
      :placeholder="field.placeholder"
      :disabled="!!!version.length"
      @change="handleOnChange($event)"
    />

    <input
      v-if="field.type === 'text' && field.name === 'email'"
      type="text"
      class="form-control"
      :name="field.name"
      :value="field.value"
      :class="{
        'control-valid': !!field.value.length && !field.error,
        'control-invalid': field.error,
      }"
      :placeholder="field.placeholder"
      @keyup="handleOnChange($event)"
    />

    <div
      v-if="field.type === 'phone'"
      class="input-group mb-3"
      :class="{
        'control-valid': !!field.value.length && !field.error,
        'control-invalid': field.error,
      }"
    >
      <div class="input-group-prepend">
        <span id="basic-addon1" class="input-group-text">{{
          field.countryCode
        }}</span>
      </div>
      <input
        type="text"
        :name="field.name"
        class="form-control"
        maxlength="9"
        :class="{
          'control-valid': !!field.value.length && !field.error,
          'control-invalid': field.error,
        }"
        :placeholder="field.placeholder"
        @keyup="handleOnChange($event)"
      />
    </div>

    <div v-if="field.type === 'checkbox'" class="form-checkbox mb-3">
      <div class="form-check">
        <input
          checked
          class="form-check-input"
          type="checkbox"
          :value="field.value"
          :name="field.name"
          @change="handleOnChange($event)"
        />
        <label class="form-check-label" for="terms">
          {{ $t("sell-form.terms-checkbox.text") }}
          <a
            href="https://www.olxautos.cl/terminos-y-condiciones/"
            class="text-blue ml-1"
            >{{ $t("sell-form.terms-checkbox.link") }}</a
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuoteFormField",
  props: ["field", "onChange"],
  computed: {
    newForm: {
      get() {
        return this.$store.state.quote.newForm;
      },
    },
    version: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "version"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
    terms: {
      get() {
        const fieldIndex = this.newForm.findIndex(
          (field) => field.name == "terms"
        );

        return this.$store.state.quote.newForm[fieldIndex].value;
      },
    },
  },
  methods: {
    handleOnChange(event) {
      this.onChange(event);
    },
  },
};
</script>
