/*eslint no-prototype-builtins: 0*/
"use strict";

export default {
  namespaced: true,
  state: {
    startTime: "",
    bookingId: "",
    saved: false,
    draftId: "",
    leadId: "",
    isComplete: false,
    report: [],
    questions: [],
    steps: [],
    currentStep: [],
    currentPosition: 0,
    loading: false,
    error: false,
  },
  mutations: {
    SET_QUESTIONS(state, questions) {
      state.questions = questions;
    },
    SET_START_TIME(state, startTime) {
      state.startTime = startTime;
    },
    SET_QUESTION(state, question) {
      const { id, value, isChecked, groupId, answered } = question;

      const groupIndex = state.questions.findIndex(
        group => group.id == groupId
      );
      const questionIndex = state.questions[groupIndex].questions.findIndex(
        q => q.id == id
      );

      state.report = [
        ...state.report.filter(element => element.id !== id),
        { id, value },
      ];
      state.questions[groupIndex].questions[
        questionIndex
      ].isAnswered = answered;

      state.questions[groupIndex].questions[questionIndex].value = value;

      state.questions[groupIndex].questions[
        questionIndex
      ].isChecked = isChecked;
    },
    SET_STEP_POSITION(state, currentPosition) {
      state.currentPosition = currentPosition;
    },
    SET_STEPS(state, steps) {
      state.steps = steps;
    },
    SET_CURRENT_STEP(state, step) {
      state.currentStep = step;
    },
    SET_COMPLETE_STEP(state, payload) {
      const { isComplete, groupId } = payload;

      const groupIndex = state.questions.findIndex(
        group => group.id == groupId
      );

      state.questions[groupIndex].isComplete = isComplete;
    },
    SET_COMPLETE_ALL(state, isComplete) {
      state.isComplete = isComplete;
    },
    SET_BOOKING_ID(state, id) {
      state.bookingId = id;
    },
    SET_DRAFT_ID(state, id) {
      state.draftId = id;
    },
    SET_LEAD_ID(state, id) {
      state.leadId = id;
    },
    SET_SAVED(state, saved) {
      state.saved = saved;
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    UPDATE_COMPLETE_ALL({ commit }, isComplete) {
      commit("SET_COMPLETE_ALL", isComplete);
    },
    UPDATE_COMPLETE_STEP({ commit }, isComplete) {
      commit("SET_COMPLETE_STEP", isComplete);
    },
    UPDATE_STEP_POSITION({ commit }, payload) {
      const { questions, nextStep } = payload;

      commit("SET_STEP_POSITION", nextStep);
      commit("SET_CURRENT_STEP", questions[nextStep]);
    },
    UPDATE_QUESTIONS({ commit }, question) {
      commit("SET_QUESTION", question);
    },
    async SAVE_INSPECTION({ commit }, payload) {
      commit("SET_LOADING", true);
      const { quoteData, report, draftId, leadId, startTime } = payload;
      const placeId = process.env.VUE_APP_PLACE_ID;
      const endTime = Date.now();

      const queryPayload = {
        country: process.env.VUE_APP_COUNTRY,
        make: quoteData.maker,
        model: quoteData.model,
        year: quoteData.year,
        trim: quoteData.version,
        inspectionType: "selfInspection",
        mileage: quoteData.milage,
        licensePlate: "",
        price: "0",
      };

      report.forEach(item => (queryPayload[item.id] = item.value));

      const query = {
        country: process.env.VUE_APP_COUNTRY,
        id: process.env.VUE_APP_CONFIG_ID,
        report: queryPayload,
        startTime,
        endTime,
        leadId,
        draft: draftId,
      };

      // const query = { "country": "CL", "id": "CLSI0001run", "report": { "country": "CL", "make": "CHEVROLET", "model": "CORSA", "year": "2008", "trim": "1.6 SWING DH CD MT 5P", "inspectionType": "selfInspection", "mileage": 23, "licensePlate": "", "price": "0", "isClassBCar": false, "isMiningVehicle": false, "IsTotalLossAuction": false, "isMaintenanceUpToDate": "inspection.field.option.missing", "isCirculacionDocumentsInHand": false, "isTechnicalDocumentInHand": false, "carHasOpenDebts": false, "wasPurchasedMoreThan60Days": false, "hasExtraSetOfKeys": false, "superficialScratchesRating": "inspection.field.option.regular1", "hasBeenInAccident": false, "deepScratchesRating": "inspection.field.option.regular1", "repaintInDifferentColourQuality": "inspection.field.option.regular1", "wheelConditionRating": "inspection.field.option.regular1", "tireConditionRating": "inspection.field.option.regular1", "seatUpholsteryRating": "inspection.field.option.regular1", "dashboardConditionRating": "inspection.field.option.regular1", "interiorDoorConditionRating": "inspection.field.option.regular1", "steeringWheelAndGearStickConditionRating": "inspection.field.option.regular1", "doesEngineRunIrregularly": false, "doesCarLeaksFluids": false, "hasNoisyTransmission": false, "hasGearShiftingProblems": false, "doWarningLightsTurnOnOnIgnition": false, "airConditioningFunctionRating": "inspection.field.option.regular1", "hasCarHadElectricalRepairs": false }, "draft": "8e6d1183-b220-49aa-b76a-88ab2c78720a" }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      };

      fetch(
        `${process.env.VUE_APP_SELF_INSPECTION_API}createInspection_si`,
        requestOptions
      )
        .then(async response => {
          const data = await response.json();

          return data;
        })
        .then(data => {
          const deleteRequestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ draft: draftId }),
          };

          return fetch(
            `${process.env.VUE_APP_SELF_INSPECTION_API}deleteDraft_si`,
            deleteRequestOptions
          );
        })
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              deleteInspectionOnlineDraft: { deleted },
            },
          } = data;

          commit("SET_SAVED", deleted);
          commit("SET_ERROR", false);
        })
        .then(() => {
          const getBookingRequestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          };

          return fetch(
            `${process.env.VUE_APP_SELF_INSPECTION_API}getBooking?placeId=${placeId}`,
            getBookingRequestOptions
          );
        })
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              availableSlots: { slots },
            },
          } = data;

          return slots;
        })
        .then(slots => {
          const earliestDate = [];

          for (const key in slots) {
            if (slots.hasOwnProperty(key)) {
              const element = slots[key];
              let date = new Array();
              for (const k in element) {
                if (element.hasOwnProperty(k)) {
                  var ms = Date.parse(k);
                  var fecha = new Date(ms);

                  date["date"] = k;

                  date["times"] = slots[key][k];
                }
                earliestDate.push(date);
              }
            }
          }

          const createBookingQuery = {
            date: earliestDate[0].date,
            time: earliestDate[0].times[0],
            plate: quoteData.plate,
            source: "",
            comments:
              "Teléfono: " + quoteData.phone + ", Email: " + quoteData.email,
            make: quoteData.maker,
            model: quoteData.model,
            trim: quoteData.version,
            year: quoteData.year,
            email: quoteData.email,
            placeId,
            leadId,
            name: "Autoevaluacion",
            phone: quoteData.phone,
            phone2: "",
            minPrice: quoteData.minPrice,
            maxPrice: quoteData.maxPrice,
            utmSource: "direct",
            status: "",
          };

          const createBookingRequestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(createBookingQuery),
          };

          return fetch(
            `${process.env.VUE_APP_SELF_INSPECTION_API}updateCarLead`,
            createBookingRequestOptions
          );
        })
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              createAppointment: { bookingId },
            },
          } = data;

          window.dataLayer.push({
            event: "gaEvent",
            eventCategory: "form",
            eventAction: "interact",
            eventLabel: "sendLead Latam",
            cdMake: quoteData.maker,
            cdModel: quoteData.model,
            cdTrim: quoteData.version,
            cdYear: quoteData.year,
            cdMileage: quoteData.milage,
            cdPrice: quoteData.minPrice
          });

          commit("SET_BOOKING_ID", bookingId);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.log(error);
          // console.error("There was an error!", error);
        });
    },
    async SAVE_DRAFT({ commit }, payload) {
      commit("SET_LOADING", true);
      const { quoteData, report, leadId, draftId, startTime } = payload;

      const queryPayload = {
        country: process.env.VUE_APP_COUNTRY,
        make: quoteData.maker,
        model: quoteData.model,
        year: quoteData.year,
        trim: quoteData.version,
        inspectionType: "selfInspection",
        mileage: quoteData.milage,
        licensePlate: "",
        price: "0",
      };

      report.forEach(item => (queryPayload[item.id] = item.value));

      const query = {
        country: process.env.VUE_APP_COUNTRY,
        id: process.env.VUE_APP_CONFIG_ID,
        startTime,
        onlineDraft: draftId,
        leadId,
        report: queryPayload,
      };

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(query),
      };

      fetch(
        `${process.env.VUE_APP_SELF_INSPECTION_API}saveDraft_si`,
        requestOptions
      )
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              saveInspectionOnlineDraft: { onlineDraftId },
            },
          } = data;

          commit("SET_DRAFT_ID", onlineDraftId);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.log(error);
          // console.error("There was an error!", error);
        });
    },
    async GET_QUESTIONS({ commit }) {
      commit("SET_LOADING", true);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          country: process.env.VUE_APP_COUNTRY,
          id: process.env.VUE_APP_CONFIG_ID_RUN
        }),
      };
      fetch(
        `${process.env.VUE_APP_SELF_INSPECTION_API}getQuestions_si`,
        requestOptions
      )
        .then(async response => {
          const data = await response.json();

          const {
            data: {
              config: {
                config: { groups: groups },
              },
            },
          } = data;

          const removeFirstStep = groups.splice(0, 1);

          const questions = [];
          const steps = [];

          groups.forEach((group, i) => {
            const questionSet = [];

            group.data.forEach(q => {
              const question = {
                id: q.id,
                title: q.title,
                required: q.required,
                component: q.component,
                options: q.options,
                isChecked: false,
                value: false,
                values: [],
                isBlocker: q.allowProgressIf ? !q.allowProgressIf.equals : null,
              };

              questionSet.push(question);
            });

            questions.push({
              id: group.id,
              title: group.title,
              questions: questionSet,
              isComplete: false,
              isLast: i === groups.length - 1,
            });

            const step = {
              position: i + 1,
              isActive: i + 1 === 1 ? true : false,
            };

            steps.push(step);
          });

          const startTime = Date.now();

          commit("SET_START_TIME", startTime);
          commit("SET_QUESTIONS", questions);
          commit("SET_STEPS", steps);
          commit("SET_CURRENT_STEP", questions[0]);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.log(error);
          // console.error("There was an error!", error);
        });
    },
  },
  getters: {
    startTime: state => state.startTime,
    saved: state => state.saved,
    bookingId: state => state.bookingId,
    draftId: state => state.draftId,
    isComplete: state => state.isComplete,
    report: state => state.report,
    questions: state => state.questions,
    steps: state => state.steps,
    loading: state => state.loading,
    error: state => state.error,
  },
};
