import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import GTM from "./plugins/GTM";
import i18n from "./plugins/i18n";
import "./plugins";

import "./styles/app.scss";

Vue.config.productionTip = false;

new Vue({
  store,
  i18n,
  router,
  GTM,
  render: h => h(App),
}).$mount("#app");
