<template>
  <form>
    <div class="form-row mb-0">
      <div class="col-6">
        <input
          v-model="plate"
          :disabled="loading"
          :placeholder="$t('sell-form.plate-form.input')"
          type="text"
          class="form-control mb-3 mb-lg-0"
        />
      </div>
      <div class="col-6">
        <button
          :disabled="loading || plate.length < 1"
          class="btn btn-primary d-flex align-items-center justify-content-center w-100 btn-small"
          @click.prevent="getPlate(plate)"
        >
          <div v-if="loading" class="spinner-border text-primary" role="status">
            <span class="sr-only">Loading...</span>
          </div>
          <div v-else>
            <IconPlate class="mr-1" />
            {{ $t("sell-form.plate-form.button") }}
          </div>
        </button>
      </div>
      <div class="col-12">
        <small v-if="error" class="form-text text-danger">
          {{ $t("sell-form.plate-form.error") }}
        </small>
      </div>
    </div>
  </form>
</template>

<script>
import IconPlate from "@/components/IconPlate";
import { mapActions } from "vuex";

export default {
  name: "PlateForm",
  components: { IconPlate },
  data: () => {
    return {
      newPlate: "",
    };
  },
  computed: {
    plate: {
      get() {
        return this.$store.state.form.plate;
      },
      set(value) {
        this.$store.commit("form/SET_PLATE", value);
      },
    },
    carData: {
      get() {
        return this.$store.state.form.carData;
      },
    },
    loading: {
      get() {
        return this.$store.state.form.loading;
      },
    },
    error: {
      get() {
        return this.$store.state.form.error;
      },
    },
  },
  mounted() {},
  methods: {
    ...mapActions({
      GET_PLATE: "form/GET_PLATE",
      CLEAR_FORM_VALUES: "quote/CLEAR_FORM_VALUES",
    }),
    getPlate(plate) {
      this.CLEAR_FORM_VALUES();
      this.GET_PLATE(plate);
    },
  },
};
</script>
