import Vue from "vue";
import Vuex from "vuex";
import formStore from "./form";
import quoteStore from "./quote";
import inspectionStore from "./inspection";

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  state: {},
  modules: {
    form: formStore,
    quote: quoteStore,
    inspection: inspectionStore,
  },
});
