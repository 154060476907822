"use strict";

export default {
  namespaced: true,
  state: {
    loading: false,
    plate: "",
    carData: {},
    error: false,
  },
  mutations: {
    SET_PLATE(state, plate) {
      state.plate = plate;
    },
    SET_CAR_DATA(state, carData) {
      state.carData = carData;
    },
    CLEAR_CAR_DATA(state) {
      state.carData = {};
    },
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
    SET_ERROR(state, error) {
      state.error = error;
    },
  },
  actions: {
    CLEAR_CAR_DATA({ commit }) {
      commit("CLEAR_CAR_DATA");
    },
    async GET_PLATE({ commit }, plate) {
      commit("SET_LOADING", true);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          query:
            '{getCarByPlate(country:"' + process.env.VUE_APP_COUNTRY + '",plate:"' +
            plate +
            '"){make,model,year,quote}}',
        }),
      };

      fetch(`${process.env.VUE_APP_SELF_INSPECTION_API}cardata`, requestOptions)
        .then(async response => {
          const data = await response.json();

          const {
            data: { getCarByPlate },
          } = data;

          if (!getCarByPlate.make) {
            commit("SET_LOADING", false);
            commit("SET_ERROR", true);
            return;
          }

          commit("SET_CAR_DATA", getCarByPlate);
          commit("SET_ERROR", false);
          commit("SET_LOADING", false);
        })
        .catch(error => {
          commit("SET_LOADING", false);
          commit("SET_ERROR", true);
          console.error("There was an error!", error);
        });
    },
  },
  getters: {
    plate: state => state.plate,
    carData: state => state.carData,
    loading: state => state.loading,
    error: state => state.error,
  },
};
