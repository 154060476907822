var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-group"},[(_vm.field.type === 'select')?_c('select',{staticClass:"custom-select",class:{ 'control-valid': !!_vm.field.value.length },attrs:{"name":_vm.field.name,"disabled":!_vm.field.options.length},on:{"change":function($event){return _vm.handleOnChange($event)}}},[_c('option',{attrs:{"value":"","selected":""}},[_vm._v(" "+_vm._s(_vm.field.value || _vm.field.placeholder)+" ")]),_vm._l((_vm.field.options),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2):_vm._e(),(_vm.field.type === 'text' && _vm.field.name === 'milage')?_c('input',{staticClass:"form-control",class:{ 'control-valid': !!_vm.field.value },attrs:{"type":"text","name":_vm.field.name,"placeholder":_vm.field.placeholder,"disabled":!!!_vm.version.length},domProps:{"value":_vm.field.value},on:{"change":function($event){return _vm.handleOnChange($event)}}}):_vm._e(),(_vm.field.type === 'text' && _vm.field.name === 'email')?_c('input',{staticClass:"form-control",class:{
      'control-valid': !!_vm.field.value.length && !_vm.field.error,
      'control-invalid': _vm.field.error,
    },attrs:{"type":"text","name":_vm.field.name,"placeholder":_vm.field.placeholder},domProps:{"value":_vm.field.value},on:{"keyup":function($event){return _vm.handleOnChange($event)}}}):_vm._e(),(_vm.field.type === 'phone')?_c('div',{staticClass:"input-group mb-3",class:{
      'control-valid': !!_vm.field.value.length && !_vm.field.error,
      'control-invalid': _vm.field.error,
    }},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text",attrs:{"id":"basic-addon1"}},[_vm._v(_vm._s(_vm.field.countryCode))])]),_c('input',{staticClass:"form-control",class:{
        'control-valid': !!_vm.field.value.length && !_vm.field.error,
        'control-invalid': _vm.field.error,
      },attrs:{"type":"text","name":_vm.field.name,"maxlength":"9","placeholder":_vm.field.placeholder},on:{"keyup":function($event){return _vm.handleOnChange($event)}}})]):_vm._e(),(_vm.field.type === 'checkbox')?_c('div',{staticClass:"form-checkbox mb-3"},[_c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"checked":"","type":"checkbox","name":_vm.field.name},domProps:{"value":_vm.field.value},on:{"change":function($event){return _vm.handleOnChange($event)}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"terms"}},[_vm._v(" "+_vm._s(_vm.$t("sell-form.terms-checkbox.text"))+" "),_c('a',{staticClass:"text-blue ml-1",attrs:{"href":"https://www.olxautos.cl/terminos-y-condiciones/"}},[_vm._v(_vm._s(_vm.$t("sell-form.terms-checkbox.link")))])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }