<template>
  <div id="app">
    <header>
      <Navigation />
    </header>
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";

export default {
  name: "App",
  components: {
    Navigation,
    Footer,
  },
  mounted() {
    this.$gtm.enable(process.env.VUE_APP_GA_ENABLED);
    console.log("GA is enabled: ", this.$gtm.enabled());
  },
  updated() {
    window.scrollTo(0, 0);
  },
  beforeCreate() {
    const lang = this.$route.query.lang;

    this.$i18n.locale = lang || process.env.VUE_APP_I18N_LOCALE;
  },
};
</script>
